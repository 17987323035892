import React from "react"
import Navbar from "../components/navbar"
import * as styles from "./index.module.css"
import { StaticImage } from "gatsby-plugin-image"

export default function Home() {
  return (
    <div style={{ display: "grid", height: "100vh" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
        }}
        layout="fullWidth"
        alt=""
        src={"../../content/images/main.jpg"}
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          alignItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Navbar />
          <section>
            <div className={styles.header}>
              <h1>Sugarblue</h1>
              <h2>funkyfize the pop</h2>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
